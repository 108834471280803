import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { Config } from "@nichemarketinginc/wa-ux-config";

import * as Sentry from "@sentry/browser";

// define Sentry limiter object
let sentryEventLimiter: {values: string[], expiry: string} = {values: [], expiry: ''};
try {
  if(localStorage && typeof localStorage === 'object' && localStorage.getItem){
    const eventLimiterTemp = localStorage.getItem('sentryEventLimiter');
    if (null !== eventLimiterTemp) {
      const parsedObj = JSON.parse(eventLimiterTemp);
      const then = new Date(parsedObj.expiry);
      const now = new Date();
      const hoursBetweenDates = Math.abs(then.getTime() - now.getTime()) / (60 * 60 * 1000);

      // if older than 24 hours then flush the duplicate sentry message list
      if (hoursBetweenDates > 24) {
        localStorage.removeItem("sentryEventLimiter");
      } else {
        sentryEventLimiter = JSON.parse(eventLimiterTemp);
      }
    }
  }
} catch (e) {
  console.log('sentryEventLimiter :: Unable to load sentryEventLimiter', e);
}

Sentry.init({
  dsn: Config.sentryDSN,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded",
    "ResizeObserver loop completed with undelivered notifications.",
    "/.*assets\/external.*/",  // eslint-disable-line no-useless-escape
  ],
  environment: Config.buildEnvironment,
  release: Config.releaseVersion,
  beforeSend: (event:any, hint:any) => {
    if (window.location.hostname === 'localhost') {
      return null;
    }

    // As per: https://github.com/getsentry/sentry-javascript/issues/2514#issuecomment-603971338
    if (hint?.originalException === "Timeout") {
      return null;
    }

    // do not send event if already sent in last 24 hours
    const msg = hint?.originalException?.message;
    if (msg && sentryEventLimiter.values.includes(msg)) {
      return null;
    }

    sentryEventLimiter.values.push(msg);
    sentryEventLimiter.expiry = sentryEventLimiter.expiry.length > 0 ? sentryEventLimiter.expiry : new Date().toString();
    try {
      if (localStorage && typeof localStorage === 'object' && typeof localStorage.setItem === 'function') {
        localStorage.setItem('sentryEventLimiter', JSON.stringify(sentryEventLimiter));
      }
    } catch (e) {
      console.log('sentryEventLimiter :: Unable to load sentryEventLimiter', e);
    }

    return event;
  },
});

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
