import React, { HTMLAttributes, ReactChild } from 'react';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import ScopedCssBaseline from '@material-ui/core/ScopedCssBaseline';
import {
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import LatoWoff2 from './fonts/Lato-Regular.woff2';
import { Colors as ColorsV2 } from '../themes/Colors';

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  productionPrefix: 'core-jss',
});

const lato = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    local('Lato'),
    local('Lato-Regular'),
    url(${LatoWoff2}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

// export breakpoints and curent Device Width.
export const DeviceWidth = window.innerWidth;
export const Breakpoints = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

export const Colors = {
  Light: '#889AA7',
  Default: '#000000',
  Primary: '#4FADB7',
  Secondary: ColorsV2.primary,
  Error: '#E84D4A',
  Warning: '#FFC734',
  Background: '#F4F7F8',
  Separation: '#374A57',
};

export const Gradients = {
  orange: 'linear-gradient(225deg,#FECF9F 0%,#FF829C 100%)',
  orange2: 'linear-gradient(45deg,#FF9A9E 0%,#FAD0C4 100%)',
  orange3: 'linear-gradient(225deg,#FFCF9E 0%,#F194A7 100%)',
  purple: 'linear-gradient(225deg, #FBC2EB 0%, #A6C1EE 100%)',
  purple2: 'linear-gradient(225deg,#A5CFF9 0%,#CD67A9 100%)',
  purple3: 'linear-gradient(225deg,#979FEC 0%,#6D77C3 100%)',
  purple4: 'linear-gradient(224deg,#FBC2EB 0%,#A6C1EE 100%)',
  blue: 'linear-gradient(225deg, #8ADDF4 0%, #9EB6FA 100%)',
  green: 'linear-gradient(225deg, #B7F8DB 0%, #50A7C2 100%)',
  green2: 'linear-gradient(225deg,#9DC0F9 0%,#87CBC5 100%)',
};

const Default = '#000000';
//const DefaultLight = "#889AA7";
//const DefaultDark = "#374A57";

const Primary = '#4FADB7';
const PrimaryLight = '#4FADB7';
const PrimaryDark = '#4FADB7';

const Secondary = ColorsV2.primary;
const SecondaryLight = ColorsV2.primary;
const SecondaryDark = ColorsV2.primary;

const Error = '#E84D4A';
const ErrorLight = '#E84D4A';
const ErrorDark = '#E84D4A';

const Warning = '#FFC734';
const WarningLight = '#FFC734';
const WarningDark = '#FFC734';

export interface ThemeProps extends HTMLAttributes<HTMLDivElement> {
  children?: ReactChild;
  type?: string;
}

const ThemeProvier = (props: ThemeProps) => {
  const theme = React.useMemo(
    () =>
    createTheme({
        breakpoints: {
          values: Breakpoints,
        },
        zIndex: {
          modal: 1300,
          snackbar: 1400,
          drawer: 1200,
          appBar: 1100,
          mobileStepper: 1000,
          tooltip: 1500,
        },
        shape: { borderRadius: 4 },
        palette: {
          type: props.type && props.type === 'dark' ? 'dark' : 'light',
          tonalOffset: 0.2,
          background: { paper: '#FFFFFF', default: '#F4F5F6' },
          contrastThreshold: 3,
          grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            200: '#eeeeee',
            300: Colors.Background,
            400: '#bdbdbd',
            500: '#9e9e9e',
            600: '#757575',
            700: '#616161',
            800: '#424242',
            900: '#212121',
            A700: '#616161',
            A100: '#d5d5d5',
            A400: '#303030',
            A200: '#aaaaaa',
          },
          text: {
            primary: Default,
            secondary: Default,
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(0, 0, 0, 0.38)',
          },
          divider: '#DBE3E9',
          common: { black: '#000', white: '#FFFFFFF' },
          action: {
            hoverOpacity: 0.08,
            hover: 'rgba(0, 0, 0, 0.08)',
            selected: 'rgba(0, 0, 0, 0.14)',
            disabledBackground: 'rgba(0, 0, 0, 0.12)',
            disabled: 'rgba(0, 0, 0, 0.26)',
            active: 'rgba(0, 0, 0, 0.54)',
          },
          primary: {
            main: Primary,
            light: PrimaryLight,
            dark: PrimaryDark,
            contrastText: '#FFFFFF',
          },
          secondary: {
            main: Secondary,
            light: SecondaryLight,
            dark: SecondaryDark,
            contrastText: '#FFFFFF',
          },
          error: {
            light: ErrorLight,
            main: Error,
            dark: ErrorDark,
            contrastText: '#FFFFFF',
          },
          warning: {
            light: WarningLight,
            main: Warning,
            dark: WarningDark,
            contrastText: '#FFFFFF',
          },
        },
        typography: {
          fontFamily: ['Lato', 'Arial'].join(','),
          button: {
            textTransform: 'none',
            color: '#FFFFFF',
            fontSize: '0.875rem',
            fontWeight: 500,
            backgroundColor:
              props.type && props.type !== 'dark' ? '#FFFFFF' : 'transparent',
          },
        },
        overrides: {
          MuiCssBaseline: {
            '@global': {
              '@font-face': [lato],
            },
          },
        },
        props: {
          MuiButtonBase: {
            disableRipple: true,
          },
          MuiDialog: {
            style: {
              zIndex: 1302,
            },
          },
        },
      }),
    [props]
  );

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider generateClassName={generateClassName} injectFirst>
        <ScopedCssBaseline>
          {props.children || `No data`}
        </ScopedCssBaseline>
      </StylesProvider>
    </ThemeProvider>
  );
};

export const Theme = React.memo(ThemeProvier);
