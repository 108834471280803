const DodgerBlue = '#3B81E0';
const Sapphire = '#2B6CEA';

export const Colors = {
  // theme color codes
  primary: Sapphire,
  secondary: '#F4F7F8',
  supreme: '#67ABB5',
  dark: '#000000',
  light: '#889AA7',
  lightGrey: '#EEF2F4',
  error: '#D9615B',
  warning: '#F2C94C',
  background: '#F4F7F8',
  separation: '#374A57',
  white: '#FFFFFF',
  success: '#43A46F',
  orange: '#E98919',
  lightYellow: '#FED979',

  // Text color codes
  defaultTextDark: '#000000',
  defaultTextLight: '#FFFFFF',
  errorText: '#D9615B',
  warningText: '#F2C94C',
  successText: '#3CA14C',
  infoText: '#889AA7',

  // Button color codes
  buttonDefaultBg: '#FFFFFF',
  buttonDefaultHoverBg: '#FFFFFF',
  buttonDefaultPressedBg: '#FFFFFF',

  buttonPrimaryBg: Sapphire,
  buttonPrimaryHoverBg: '#2C84CF',
  buttonPrimaryPressedBg: '#2F71AA',

  buttonSecondaryBg: '#F4F7F8',
  buttonSecondaryHoverBg: '#EBF0F2',
  buttonSecondaryPressedBg: '#E5ECEE',

  buttonSupremeBg: '#67ABB5',
  buttonSupremeHoverBg: '#7FBFC9',
  buttonSupremePressedBg: '#579CA6',

  buttonDefaultLabelColor: '#374A57',
  buttonPrimaryLabelColor: '#FFFFFF',
  buttonSecondaryLabelColor: Sapphire,
  buttonSupremeLabelColor: '#FFFFFF',

  // TextInput color codes
  textInputDefault: '#374A57',
  textInputDisabled: '#889AA7',

  textInputPlaceholder: '#374A57',
  textInputPlaceholderDisabled: '#889AA7',

  textInputBgDefault: '#EEF2F4',
  textInputBgHighlight: '#FFFFFF',
  textInputBgDisabled: '#EEF2F4',

  textInputIconColor: '#374A57',
  textInputIconColorDisabled: '#889AA7',

  textInputBorderValue: '#D1D4D7',
  textInputBorderFocus: '#374A57',
  textInputBorderWarning: '#F2C94C',
  textInputBorderError: '#D9615B',
  textInputBorderSuccess: '#3CA14C',
  textInputBorderDisabled: '#EEF2F4',

  textInputStatusText: '#889AA7',
  textInputStatusTextWarning: '#CB9901',
  textInputStatusTextError: '#D9615B',
  textInputStatusTextSuccess: '#3CA14C',

  textInputLabelText: '#889AA7',
  textInputLabelTextFocused: '#374A57',

  textInputHelperText: '#889AA7',

  // SelectBox color codes
  selectBoxDefault: '#374A57',

  selectBoxBgDefault: '#EEF2F4',

  selectBoxBorderDefault: '#EEF2F4',

  selectBoxLabelText: '#374A57',

  // Checkox Color Codes
  checkboxPrimaryBg: Sapphire,
  checkboxSecondaryBg: '#F4F7F8',

  checkboxDefaultLabelColor: '#889aa7',
  checkboxPrimaryLabelColor: Sapphire,
  checkboxSecondaryLabelColor: '#8B9AA5',

  // Snackbar color codes
  snackbarDefaultBg: '#374A57',
  snackbarSuccessBg: '#3CA14C',
  snackbarInfoBg: DodgerBlue,
  snackbarErrorBg: '#D9615B',
  snackbarWarningBg: '#F2C94C',

  snackbarWarningLabelColor: 'rgba(0, 0, 0, 0.8)',
  snackbarLabelColor: '#FFFFFF',
}

export default Colors;