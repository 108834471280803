import React from 'react';
import { logError } from './logErrorService';

import * as Sentry from '@sentry/browser';

interface ErrorBoundaryProps {
  hasError: boolean;
  error: string;
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryProps> {
  constructor(props: Readonly<{}>) {
    super(props);
    this.state = { hasError: false, error: '' };
  }

  static getDerivedStateFromError(error: { toString: () => any }) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.toString() };
  }

  componentDidCatch(error: any, info: any) {
    // log the error to an error reporting service
    logError(error, info);
    Sentry.withScope((scope) => {
      scope.setExtras(info);
      Sentry.captureException(error);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h1>Oops!!! Something went wrong</h1>
          <p>The error: {this.state.error.toString()}</p>
        </div>
      );
    }

    // If there is no error just render the children component.
    return this.props.children;
  }
}
