import React, { Suspense, lazy } from "react";
import { Route, Router, Switch } from "react-router-dom";
import { ErrorBoundary, Theme, history } from "@nichemarketinginc/wa-ux-core";

const AppMember = lazy(() => import("./AppMember"));
const AppGuest = lazy(() => import("./AppGuest"));

function App() {
  return (
    <Theme>
      <Router history={history}>
        <Switch>
          <Route path={["/login", "/join"]} render={() => (
            <ErrorBoundary>
              <Suspense fallback="">
                <AppGuest />
              </Suspense>
            </ErrorBoundary>
          )} />
          <Route>
            <ErrorBoundary>
              <Suspense fallback="">
                <AppMember />
              </Suspense>
            </ErrorBoundary>
          </Route>
        </Switch>
      </Router>
    </Theme>
  );
}

export default App;
